import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonImg, IonThumbnail } from '@ionic/react';
import React from 'react';

class RaceBoard extends React.Component<{id: any, header: any, subtitle: any, title: any, description: any, setRace: any, openCancel: any}> {
    constructor(props) {
      super(props);
    }
  
    render() {
        if(this.props.title == "" || this.props.title ==  undefined) {
            console.log("No race board to display. Title: " + this.props.title);
            return <></>;
        } else {
            console.log("Race board avaialble to display. Title: " + this.props.title);
            const button = <IonButton onClick={() => this.props.setRace(this.props.id)} expand="full">S'inscrire</IonButton>;
            var btnRestart = <IonButton onClick={() => this.props.openCancel()} color="danger">Retour</IonButton>;
            return <><IonCard>
                        <img src={this.props.header} />
                    <IonCardHeader>
                        <IonCardSubtitle>{this.props.subtitle}</IonCardSubtitle>
                        <IonCardTitle>{this.props.title}</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        {this.props.description}
                        {button}
                    </IonCardContent>
                </IonCard>
                {btnRestart}
            </>;
        }
    }
  }

  export default RaceBoard;