import React from 'react';
import { IonActionSheet, IonButton, IonItem, IonLabel, IonThumbnail } from '@ionic/react';
import './Logos.css';
import { trash, close } from 'ionicons/icons';

class Logos extends React.Component<{nbr: any, logo: any, enabled: any, cancel: any, openCancel: any, reset: any, resCancel: any}> {
    constructor(props) {
      super(props);
    }

    render() {
        if(this.props.logo == undefined || this.props.enabled == "")
            return <></>;
        var logos = [];
        var btnRestart = <IonButton onClick={() => this.props.openCancel()} color="danger">Redémarrer</IonButton>
        for (var i = 0; i < this.props.nbr; i++) {
            if(this.props.enabled.charAt(i) == "1") {
                logos.push(<IonThumbnail key={i} slot="start">
                                <img src={this.props.logo} />
                            </IonThumbnail>);
            } else {
                console.log("Logo: " + this.props.logo);
                const logo = (this.props.logo as String).replace(".png", "_gr.png");
                logos.push(<IonThumbnail key={i} slot="start">
                                <img src={logo} />
                            </IonThumbnail>);
            }
        } 
        return <div>
                <IonItem lines="none" className="ionitem">
                    {logos}
                </IonItem>
                {btnRestart}
                <IonActionSheet
                    isOpen={this.props.cancel}
                    onDidDismiss={() => this.props.resCancel()}
                    cssClass='my-custom-class'
                    buttons={[{
                    text: 'Supprimer la course',
                    role: 'destructive',
                    icon: trash,
                    handler: () => {
                        this.props.reset();
                        //console.log('Delete clicked');
                    }
                    }, {
                    text: 'Annuler',
                    icon: close,
                    role: 'cancel',
                    handler: () => {
                        this.props.resCancel();
                    }
                    }]}
                >
                </IonActionSheet>
                </div>;
    }
  }

  export default Logos;