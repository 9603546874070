import { IonChip, IonLabel } from '@ionic/react';
import React, { Component } from 'react';

class Step extends React.Component<{race: any}> {
    constructor(props) {
      super(props);
    }
  
    render() {
      console.log("Steps tab - Race: " + this.props.race);
      if(this.props.race != "" && this.props.race != 0 && this.props.race != undefined) {
        return <>{this.props.race}</>;
      } else {
        return <div className="container">
                  <a href="./scann">
                    <IonChip outline color="danger">
                        <IonLabel>Commencez par scanner un QR code de départ</IonLabel>
                    </IonChip>
                  </a>
                </div>;
      }
    }
  }

  export default Step;