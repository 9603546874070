import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import Result from '../components/Result';

interface IRes {
  timestamp: number;
  nom: string;
  prenom: string;
  npa: string;
  categorie: string;
  genre: string;
  annee: number;
  temps: number;
}

const TabResult: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Résultat</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Course</IonTitle>
          </IonToolbar>
        </IonHeader>
        <Result items={[]} />
      </IonContent>
    </IonPage>
  );
};

export default TabResult;
