import React, { Component } from 'react';
import { IonList } from '@ionic/react';
import Position from './Position'

class PositionsList extends React.Component {
    constructor(props) {
      super(props);
      let positions: any;
    }

    componentDidMount() {
    }

    render() {
        let positions = ["Item 1", "Item 2"]
      return <div>
                {//positions.map((item, index) => (
                    //<Position key={index} item={item} />
                //))
                }
                <Position pos={1} name={"Name 1"} desc={"Desc 1"} />
                <Position pos={2} name={"Name 2"} desc={"Desc 2"} />
            </div>;
    }
  }

  export default PositionsList;