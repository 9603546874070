import React from 'react';
import { IonItem, IonList } from '@ionic/react';
import { randomInt } from 'crypto';

class Time extends React.Component<{nbr: any, times: any}> {
    constructor(props) {
      super(props);
    }

    render() {
        if(this.props.times == [])
            return <></>;
        var logos = [];
        this.props.times.map((step) =>
            logos.push(<IonItem key={Math.floor(Math.random() * 10000)} slot="start">
                    {step}
                </IonItem>))
        return <IonList>
                    {logos}
                </IonList>;
    }
  }

  export default Time;