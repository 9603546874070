import React from 'react';
import { IonButton, IonDatetime, IonInput, IonItem, IonLabel, IonList, IonSelect, IonSelectOption } from '@ionic/react';

interface IParticipant {
    parcours: any;
    nom: any;
    prenom: any;
    localite: any;
    anniversaire: any;
    genre: any;
    email: any;
}

class RaceForm extends React.Component<{id: any, part: IParticipant, present: any, setter: any, save: any, openCancel: any}> {
    constructor(props) {
      super(props);
      this.save_form = this.save_form.bind(this);
    }

    save_form() {
        if(this.props.part.parcours == ""
            || this.props.part.nom == ""
            || this.props.part.prenom == ""
            || this.props.part.genre == ""
            || this.props.part.parcours == undefined
            || this.props.part.nom == undefined
            || this.props.part.prenom == undefined
            || this.props.part.anniversaire == undefined
            || this.props.part.genre == undefined) {
                console.log("Not all fields are filled");
                this.props.present("Veuillez remplir tous les champs", 5000);
            } else if(this.props.part.anniversaire == "") {
                this.props.present("L'année de naissance doit être composée de quatre chiffres", 5000);
            } else {
                console.log("Form is going to be saved");
                console.log(this.props.part.nom);
                this.props.save();
            }
    }

    render() {
        let button_return = <IonButton onClick={() => this.props.openCancel()} color="danger">Retour</IonButton>;
        let form = <></>;
        //var range = Array(2020 - 1900 + 1).fill(2020, 1900-1).map((_, idx) => 1900 + idx);
        //console.log(range);
        let inputYear = <IonDatetime min="1900" max="2020" display-format="YYYY" picker-format="YYYY" value={this.props.part.anniversaire} onIonChange={e => this.props.setter("anniversaire", (e.detail.value!).split("-")[0])}></IonDatetime>;
        if(this.props.id == 1) {
          form = <IonList>
                  <IonItem>
                      <IonLabel>Parcours</IonLabel>
                      <IonSelect value={this.props.part.parcours} onIonChange={e => this.props.setter("parcours", e.detail.value)}>
                          <IonSelectOption value="VTT">VTT</IonSelectOption>
                          <IonSelectOption value="eVTT">VTT électrique</IonSelectOption>
                      </IonSelect>
                  </IonItem>
                  <IonItem>
                      <IonLabel position="floating">Nom</IonLabel>
                      <IonInput type="text" value={this.props.part.nom}
                          onIonChange={e => this.props.setter("nom", e.detail.value!)}></IonInput>
                  </IonItem>
                  <IonItem>
                      <IonLabel position="floating">Prénom</IonLabel>
                      <IonInput type="text" value={this.props.part.prenom}
                          onIonChange={e => this.props.setter("prenom", e.detail.value!)}></IonInput>
                  </IonItem>
                  <IonItem>
                      <IonLabel position="floating">Localité</IonLabel>
                      <IonInput type="text" value={this.props.part.localite}
                          onIonChange={e => this.props.setter("localite", e.detail.value!)}></IonInput>
                  </IonItem>
                  <IonItem>
                      <IonLabel position="floating">Année de naissance</IonLabel>
                      <IonInput type="number" min="1900" max="2020" value={this.props.part.anniversaire}
                          onIonChange={e => this.props.setter("anniversaire", e.detail.value!)}></IonInput>
                  </IonItem>
                  <IonItem>
                      <IonLabel>Genre</IonLabel>
                      <IonSelect value={this.props.part.genre} onIonChange={e => this.props.setter("genre", e.detail.value)}>
                          <IonSelectOption value="Femme">Femme</IonSelectOption>
                          <IonSelectOption value="Homme">Homme</IonSelectOption>
                      </IonSelect>
                  </IonItem>
                  <IonItem>
                      <IonLabel position="floating">Adresse e-mail</IonLabel>
                      <IonInput type="email" value={this.props.part.email}
                          onIonChange={e => this.props.setter("email", e.detail.value!)}></IonInput>
                  </IonItem>
                <IonButton onClick={() => this.save_form()} expand="full">Démarrer</IonButton>
              </IonList>;
        }
        if(this.props.id == 2) {
            this.props.setter("parcours", "Course")
            form = <IonList>
                    <IonItem>
                        <IonLabel><strong>Course à pied</strong></IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating">Nom</IonLabel>
                        <IonInput type="text" value={this.props.part.nom}
                            onIonChange={e => this.props.setter("nom", e.detail.value!)}></IonInput>
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating">Prénom</IonLabel>
                        <IonInput type="text" value={this.props.part.prenom}
                            onIonChange={e => this.props.setter("prenom", e.detail.value!)}></IonInput>
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating">Localité</IonLabel>
                        <IonInput type="text" value={this.props.part.localite}
                            onIonChange={e => this.props.setter("localite", e.detail.value!)}></IonInput>
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating">Année de naissance</IonLabel>
                        <IonInput type="number" min="1900" max="2020" value={this.props.part.anniversaire}
                          onIonChange={e => this.props.setter("anniversaire", e.detail.value!)}></IonInput>
                    </IonItem>
                    <IonItem>
                        <IonLabel>Genre</IonLabel>
                        <IonSelect value={this.props.part.genre} onIonChange={e => this.props.setter("genre", e.detail.value)}>
                            <IonSelectOption value="Femme">Femme</IonSelectOption>
                            <IonSelectOption value="Homme">Homme</IonSelectOption>
                        </IonSelect>
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating">Adresse e-mail</IonLabel>
                        <IonInput type="email" value={this.props.part.email}
                            onIonChange={e => this.props.setter("email", e.detail.value!)}></IonInput>
                    </IonItem>
                  <IonButton onClick={() => this.save_form()} expand="full">Démarrer</IonButton>
                </IonList>;
          }
          if(this.props.id == 3) {
            this.props.setter("parcours", "Course")
            form = <IonList>
                    <IonItem>
                        <IonLabel><strong>Course à pied</strong></IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating">Nom</IonLabel>
                        <IonInput type="text" value={this.props.part.nom}
                            onIonChange={e => this.props.setter("nom", e.detail.value!)}></IonInput>
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating">Prénom</IonLabel>
                        <IonInput type="text" value={this.props.part.prenom}
                            onIonChange={e => this.props.setter("prenom", e.detail.value!)}></IonInput>
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating">Localité</IonLabel>
                        <IonInput type="text" value={this.props.part.localite}
                            onIonChange={e => this.props.setter("localite", e.detail.value!)}></IonInput>
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating">Année de naissance</IonLabel>
                        <IonInput type="number" min="1900" max="2020" value={this.props.part.anniversaire}
                          onIonChange={e => this.props.setter("anniversaire", e.detail.value!)}></IonInput>
                    </IonItem>
                    <IonItem>
                        <IonLabel>Genre</IonLabel>
                        <IonSelect value={this.props.part.genre} onIonChange={e => this.props.setter("genre", e.detail.value)}>
                            <IonSelectOption value="Femme">Femme</IonSelectOption>
                            <IonSelectOption value="Homme">Homme</IonSelectOption>
                        </IonSelect>
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating">Adresse e-mail</IonLabel>
                        <IonInput type="email" value={this.props.part.email}
                            onIonChange={e => this.props.setter("email", e.detail.value!)}></IonInput>
                    </IonItem>
                  <IonButton onClick={() => this.save_form()} expand="full">Démarrer</IonButton>
                </IonList>;
          }
        return <>{form}<br />{button_return}</>;
    }
  }

  export default RaceForm;