import React from 'react'
import { IonList, IonLabel, IonItem } from '@ionic/react';
import axios from 'axios';
import { logoWindows } from 'ionicons/icons';

interface IRes {
    part: number;
    nom: string;
    prenom: string;
    localite: string;
    anniversaire: number;
    genre: string;
    temps: number;
    cat: string;
}

class Ranking extends React.Component<{ items: IRes[] }> {
    res: any;
    noReload: any;

    constructor(props) {
        super(props);
        this.getRace = this.getRace.bind(this);
        this.convertTime = this.convertTime.bind(this);
        this.capitalizeFirstLetter = this.capitalizeFirstLetter.bind(this);
        this.noReload = 0;
    }

    componentDidMount() {
        if(localStorage.getItem("state") != "" && localStorage.getItem("state") != null) {       
            var race = JSON.parse(localStorage.getItem("state")).race;
            this.getRace(race);
            console.log("Race selected");
        } else {
            this.getRace(0);
            console.log("No race selected");
        }
    }

    convertTime(result: number) {
        var sec_num = result;
        var hours   = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);
        var hours_t = "";
        var minutes_t = "";
        var seconds_t = "";
    
        if (hours   < 10) {hours_t   = "0"+hours;} else {hours_t = hours.toString();}
        if (minutes < 10) {minutes_t = "0"+minutes;} else {minutes_t = minutes.toString();}
        if (seconds < 10) {seconds_t = "0"+seconds;} else {seconds_t = seconds.toString();}
        
        return hours_t + ":" + minutes_t + ":" + seconds_t;
    }

    capitalizeFirstLetter(st: any)
    {
        return st.charAt(0).toUpperCase() + st.slice(1).toLowerCase();
    }

    getRace(race: any) {
        if(race == 0) {
            this.noReload = 0;
            this.res = <div>
                            <p>Veuillez d'abord scanner un QR code de départ pour sélectionner une course.</p>
                            <p>Pour voir les résultats, consulter le site Internet de la course</p>
                    </div>;
            this.forceUpdate();
        } else {
            this.noReload = 1;
            var formData = new FormData();
            formData.append("race", race);
            axios({
                method: "post",
                url: 'https://apipar.valais.cloud/rank/5255b44b1ac4f1df35f03c6f09ab46f2',
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
            })
                .then(response => {
                    const data = response.data;
                    if (data['Error'] == "Unknown race") {
                        return [];
                    }
                    console.log(data);
                    this.res = data.map((item: IRes) => (
                        <IonItem key={item.part}>
                            <IonLabel><h2>{this.capitalizeFirstLetter(item.nom)} {this.capitalizeFirstLetter(item.prenom)}</h2><p>{item.anniversaire} - {item.cat} - <strong>{this.convertTime(item.temps)}</strong></p></IonLabel></IonItem>
                    ))
                    this.forceUpdate();
                    return data as IRes[];
                })
                .catch(function (response) {
                    console.log("API error: " + response);
                    return [] as IRes[];
                });
        }
    }

    render() {
        return (
            <>{this.res}</>
        );
    }
}

export default Ranking;