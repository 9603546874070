import React, { Component } from 'react';
import { Plugins } from '@capacitor/core';
import { IonChip, IonContent, IonHeader, IonLabel, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import Scanner from './Scanner'
import RaceBoard from './RaceBoard'
import RaceForm from './RaceForm';
import Logos from './Logos'
import Time from './Time'
import Finish from './Finish';
import axios from 'axios';
import './RaceDashboard.css';

const { Device } = Plugins;

interface IPart {
    scannerResult: any;
}

interface IParcours {
    title: any;
    subtitle: any;
    steps: any;
    useFirstAsLast: any;
    logo: any;
    description: any;
    header: any;
}

interface IParticipant {
    parcours: any;
    nom: any;
    prenom: any;
    localite: any;
    anniversaire: any;
    genre: any;
    email: any;
}

interface IState {
    title: any;
    subTitle: any;
    race: any;
    form: any;
    startRace: any;
    finish: any;
    raceForm: any;
    logo_enabled: any;
    logo: any;
    uID: any;
    scannInfo: any;
    button: any;
    notification: any;
    part: IParticipant;
    parcours: IParcours;
    loading: Boolean;
    dataSaved: Boolean;
    times: any;
    startTime: any;
    result: any;
    partID: any;
    firstScan: any;
    cancel: Boolean;
}

class RaceDashboard extends React.Component<{ present: any }> {
    items: IPart;
    state: IState;

    constructor(props) {
        super(props);
        this.save_state = this.save_state.bind(this);
        this.restore_state = this.restore_state.bind(this);
        this.startRace = this.startRace.bind(this);
        this.save_participant_data = this.save_participant_data.bind(this);
        this.save_scan_data = this.save_scan_data.bind(this);
        this.scanned = this.scanned.bind(this);
        this.step_scanned = this.step_scanned.bind(this);
        this.step_validation = this.step_validation.bind(this);
        this.finish = this.finish.bind(this);
        this.checkRaceCode = this.checkRaceCode.bind(this);
        this.getInfo = this.getInfo.bind(this);
        this.getRace = this.getRace.bind(this);
        this.display_form = this.display_form.bind(this);
        this.convertTm = this.convertTm.bind(this);

        this.items = { scannerResult: this.scanned } as IPart;
        this.state = {
            title: 'Scanner un parcours',
            subTitle: 'Scanner un code de départ',
            logo_enabled: "",
            race: 0,
            form: 0,
            startRace: 0,
            finish: 0,
            raceForm: <></>,
            logo: <></>,
            uID: '',
            scannInfo: <p>Pour débuter, cliquez sur le bouton ci-dessous et scanner un QR code de départ</p>,
            button: <Scanner items={this.items} present={this.props.present} />,
            notification: <></>,
            part: {} as IParticipant,
            parcours: {} as IParcours,
            loading: false,
            dataSaved: false,
            times: [],
            startTime: 0,
            result: 0,
            partID: 0,
            firstScan: 0,
            cancel: false
        };
    }

    componentDidMount() {
        console.log("Title: " + this.state.title);
        this.getInfo();
        if(localStorage.getItem("state") != "" && localStorage.getItem("state") != null) {       
            this.restore_state();
        }
        //this.forceUpdate();
    }

    save_state() {
        console.log(JSON.stringify(this.state));
        localStorage.setItem("state", JSON.stringify(this.state));
    }

    restore_state() {
        var st = JSON.parse(localStorage.getItem("state")) as IState;
        console.log(st.title);
        this.setState({
            title: st.title,
            subTitle: st.subTitle,
            logo_enabled: st.logo_enabled,
            race: st.race,
            form: st.form,
            startRace: st.startRace,
            finish: st.finish,
            raceForm: <></>,
            logo: <></>,
            uID: st.uID,
            partID: st.partID,
            scannInfo: <></>,
            button: st.button,
            notification: <></>,
            part: st.part as IParticipant,
            parcours: st.parcours as IParcours,
            loading: st.loading,
            times: st.times,
            startTime: st.startTime,
            result: st.result,
            dataSaved: st.dataSaved
        });
    }
    
    async getInfo() {
        if(this.state.uID == "") {
            Device.getInfo().then((info) => {
                this.setState({uID: info['uuid']});
                this.forceUpdate();
            });
        }
    }

    setValue(key: any, value: any) {
        let part = this.state.part;
        switch (key) {
            case "nom":
                part.nom = value;
                break;
            case "prenom":
                part.prenom = value;
                break;
            case "localite":
                part.localite = value;
                break;
            case "anniversaire":
                part.anniversaire = value;
                break;
            case "genre":
                part.genre = value;
                break;
            case "email":
                part.email = value;
                break;
            case "parcours":
                part.parcours = value;
                break;
        }
        this.setState({ part: part });
        console.log(part);
    }

    getForm(id: any, part: IParticipant, setValue: any) {
        switch (id) {
            case 1:
                return <></>;
            default:
                return <></>;
        }
    }

    checkRaceCode(code: any, step: any) {
        console.log("checkRaceCode: " + code);
        console.log("Step: " + step);
        var i = "https://parcours.valais.cloud/id/";
        var t = "https://parcours.valais.cloud/tag/";

        if (code.startsWith(i) && step == 0) {
            console.log("checkRaceCode (i): " + code.substring(i.length));
            console.log("useFirstAsLast: " + this.state.parcours.useFirstAsLast);
            this.save_scan_data(code.substring(i.length));
            if(this.state.race != 0) {
                return 0;
            }
            return 1;
        } else if (code.startsWith(i) && step == 1) {
            // Read as last scan when enabled
            if(this.state.race != 0 && this.state.parcours.useFirstAsLast == 1) {
                console.log("checkRaceCode (i): " + code.substring(i.length));
                this.save_scan_data(code.substring(i.length));
                return 2;
            }
        }
        if (code.startsWith(t) && step == 1) {
            console.log("checkRaceCode (t): " + code.substring(t.length));
            this.save_scan_data(code.substring(t.length));
            return 2;
        }

        return 0;
    }

    getRace(race) {
        var formData = new FormData();
        formData.append("race", race);
        axios({
            method: "post",
            url: 'https://apipar.valais.cloud/race/5255b44b1ac4f1df35f03c6f09ab46f2',
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(response => {
                const data = response.data;
                if(data['Error'] == "Unknown race") {
                    this.props.present("Tag inconnu", 2000);
                    return 0;
                }
                //handle success
                //this.setState({dataSaved: true});
                console.log(data);
                console.log("Race is set to: " + race);
                this.setState({parcours: data as IParcours, race: race});
                console.log("Parcours: " + (data as IParcours).title);
                this.save_state();
                return 1;
            })
            .catch(function (response) {
                //handle error
                console.log("API error");
                return 0;
            });
    }

    save_scan_data(tag: any)  {
        console.log("Get data for tag (" + tag + ") scanned");
        this.setState({ loading: true });
        var formData = new FormData();
        formData.append("tag", tag);
        formData.append("h", this.state.uID);
        formData.append("part", this.state.partID);
        axios({
            method: "post",
            url: 'https://apipar.valais.cloud/tag/5255b44b1ac4f1df35f03c6f09ab46f2',
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(response => {
                //handle success
                const data = response.data;
                if(response.data.race == undefined) {
                    console.log("Unknown code");
                    this.props.present("Code non reconnu", 2000);
                    this.save_state();
                    return 0;
                }
                //this.setState({race: data.race})
                console.log("Race: " + data.race);
                console.log("Pos: " + data.pos);
                console.log("Timestamp: " + data.timestamp);
                // Set the starting time
                if(this.state.startTime == 0) {
                    this.setState({firstScan: data.id, startTime: data.timestamp});
                }
                //this.setState({race: data.race});
                if(this.state.race == 0) {
                    this.getRace(data.race);
                } 
                console.log("Return value (pos): " + data.pos);
                if(data.pos > 1) {
                    this.step_validation(data.race, data.pos, data.timestamp);
                } else if(data.pos == 1 && this.state.parcours.useFirstAsLast == 1) {
                    var p = 0;
                    for(var i = 0; i < this.state.logo_enabled.length; i++) {
                        if(this.state.logo_enabled.charAt(i) == 0) {
                            p = i;
                            break;
                        }
                    }
                    if(p == this.state.logo_enabled.length-1) {
                        this.step_validation(data.race, data.pos, data.timestamp);
                    }
                }
                this.save_state();
                return 1;
            })
            .catch(response => {
                //handle error
                console.log("Error: " + response);
                return 0;
            });
    }

    startRace(result: any) {
        let zero = "1";
        for(let i = 0; i < this.state.parcours.steps-1; i++) {
            zero += "0";
        }
        console.log("Zeros: " + zero);
        this.state.times.push("Heure de départ: " + this.convertTm(this.state.startTime));
        console.log("PartID: " + result['Insert']);
        this.setState({partID: result['Insert'], startRace: 1, raceForm: <></>, logo_enabled: zero, title: "C'est parti!", scannInfo: "Scannez la prochaine étape"});
        this.save_state();
    }

    save_participant_data() {
        //this.setState({ loading: true });
        var formData = new FormData();
        formData.append("id", this.state.uID);
        formData.append("scan", this.state.firstScan);
        formData.append("nom", this.state.part.nom);
        formData.append("prenom", this.state.part.prenom);
        formData.append("localite", this.state.part.localite);
        formData.append("anniversaire", this.state.part.anniversaire);
        formData.append("genre", this.state.part.genre);
        formData.append("email", this.state.part.email);
        if(this.state.part.parcours != undefined && this.state.part.parcours != "")
            formData.append("sport", this.state.part.parcours);
        else
            formData.append("sport", "");
        formData.append("parcours", this.state.race);
        console.log(formData);
        axios({
            method: "post",
            url: 'https://apipar.valais.cloud/participant/5255b44b1ac4f1df35f03c6f09ab46f2',
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(response => {
                //handle success
                console.log("Participation saved: " + response);
                this.startRace(response.data);
                this.save_state();
                return 1;
            })
            .catch(response =>  {
                //handle error
                console.log("Error when saving participation: " + response);
                return 0;
            });
    }

    scanned(data: any) {
        const resultType = this.checkRaceCode(data, 0);
        console.log("QR Code result: " + data + ": " + resultType);
        if (resultType == 1) {
            this.setState({ title: 'Enregistrement'});
            this.setState({notification: <></>})
        } else {
            this.props.present("Scannez d'abord un QR code de départ", 5000);
            this.setState({notification: <IonChip outline color="danger"><IonLabel>Scannez d'abord un QR code de départ</IonLabel></IonChip>})
        }
    }

    step_scanned(data: any) {
        console.log("QR code for step: " + data);
        const resultType = this.checkRaceCode(data, 1);
        console.log("ResultType: " + resultType);
        if (resultType == 1) {
            this.props.present("QR code non valide", 5000);
        }
    }

    step_validation(race: any, pos: any, timestamp: any) {
        console.log("step_validation -> race: " + race + ", pos: " + pos + ", state: " + this.state.race);
        var p = 0;
        for(var i = 0; i < this.state.logo_enabled.length; i++) {
            if(this.state.logo_enabled.charAt(i) == 0) {
                p = i;
                break;
            }
        }

        if(race == this.state.race) {
            // Check if the first tag is used as the last tag
            console.log("Test");
            console.log(pos + ", " + this.state.parcours.useFirstAsLast + ", " + this.state.logo_enabled);
            if (pos == 1 && this.state.parcours.useFirstAsLast == 1 && p == this.state.logo_enabled.length-1) {
                pos = this.state.logo_enabled.length;
            } 
            if (p == pos-1) {
                var en = this.state.logo_enabled.substr(0, pos-1) + "1" + this.state.logo_enabled.substr(pos, this.state.logo_enabled.length);
                console.log(en);
                this.setState({logo_enabled: en});
                this.props.present("Etape " + pos + " validée", 5000);
                this.state.times.push("Temps de passage " + pos + ": " + this.convertTm(timestamp));
                this.setState({notification: <IonChip outline color="success"><IonLabel>Etape {pos} validée</IonLabel></IonChip>})
                this.forceUpdate();
                if(pos == this.state.logo_enabled.length) {
                    this.finish();
                }
            } else if (p < pos-1) {
                var po = p + 1;
                this.props.present("Scannez d'abord l'étape " + po, 5000);
                this.setState({notification: <IonChip outline color="danger"><IonLabel>Scannez d'abord l'étape {p+1}</IonLabel></IonChip>})
            } else {
                this.props.present("Etape " + pos + " déjà scannée", 5000);
                this.setState({notification: <IonChip outline color="danger"><IonLabel>Etape {pos} déjà scannée</IonLabel></IonChip>})
            }
        } else {
            this.props.present("Ce QR code ne fait pas partie de la course", 5000);
        }
    }

    convertTm(timestamp: any) {
        var date = new Date(timestamp * 1000);
        return date.getHours().toString().padStart(2, "0") + ":" + date.getMinutes().toString().padStart(2, "0") + ":" + date.getSeconds().toString().padStart(2, "0");
    }

    finish() {
        this.setState({subTitle: "Terminé", finish: 1, steps: "", parcours: {} as IParcours});
        this.forceUpdate();
        this.props.present("Terminé!", 5000);
        var formData = new FormData();
        formData.append("part", this.state.partID);
        axios({
            method: "post",
            url: 'https://apipar.valais.cloud/result/5255b44b1ac4f1df35f03c6f09ab46f2',
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(response => {
                const data = response.data;
                this.setState({result: data['res']});
                this.save_state();
                return 1;
            })
            .catch(function (response) {
                //handle error
                console.log("API error");
                return 0;
            });
        
        //this.setState({notification: <IonChip outline color="success"><IonLabel>Terminé! Félicitations</IonLabel></IonChip>})
    }

    display_form(id: any) {
        console.log("Form is going to be displayed for: " + id);
        const registerForm = <RaceForm id={id} present={this.props.present} part={this.state.part} setter={(key: any, value: any) => this.setValue(key, value)} save={() => this.save_participant_data()} openCancel={() => {localStorage.setItem("state", ""); window.location.reload();}} />;
        this.setState({ raceForm: registerForm, form: id });
    }

    render() {
        //let btnTest = <IonButton onClick={() => this.checkRaceCode("https://parcours.valais.cloud/id/ae41d53647957abd70afc5aea3a233f6", 0)}>Scan</IonButton>;
        let btnTest = <></>;
        let button = <></>;
        let info = "";
        let raceBoard = <></>;

        if(this.state.form == 0) {
            raceBoard = <RaceBoard id={this.state.race} title={this.state.parcours.title} subtitle={this.state.parcours.subtitle}
                description={this.state.parcours.description} header={this.state.parcours.header} setRace={(id: any) => this.display_form(id)} openCancel={() => {localStorage.setItem("state", ""); window.location.reload();}} />;
        } else {
            raceBoard = <></>;
        }

        if(this.state.parcours.title == "" || this.state.parcours.title == undefined)
        {
            button = this.state.button;
            info = this.state.scannInfo;
        } else if(this.state.startRace == 1) {
            let item = {} as IPart;
            item.scannerResult = this.step_scanned;
            button = <Scanner items={item} present={this.props.present} />;
            info = this.state.scannInfo;
        } else {
            button = <></>;
        }

        if(this.state.finish == 1) {
            button = <></>;
            info = "";
            console.log("Résultat: " + this.state.result);
            var sec_num = this.state.result;
            var hours   = Math.floor(sec_num / 3600);
            var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
            var seconds = sec_num - (hours * 3600) - (minutes * 60);
            var hours_t = "";
            var minutes_t = "";
            var seconds_t = "";
        
            if (hours   < 10) {hours_t   = "0"+hours;} else {hours_t = hours.toString();}
            if (minutes < 10) {minutes_t = "0"+minutes;} else {minutes_t = minutes.toString();}
            if (seconds < 10) {seconds_t = "0"+seconds;} else {seconds_t = seconds.toString();}
            
            var tm = hours_t + "H  " + minutes_t + "mn " + seconds_t + "sec";
            console.log("Result: " + this.state.result);
            raceBoard = <Finish res={tm} restart={() => {localStorage.setItem("state", ""); window.location.reload();}} />;
        }

        return <IonPage>
                    <IonHeader>
                        <IonToolbar>
                            <IonTitle>{this.state.title}</IonTitle>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent className="ioncontent" fullscreen>
                        <IonHeader collapse="condense">
                            <IonToolbar>
                                <IonTitle size="large">{this.state.subTitle}</IonTitle>
                            </IonToolbar>
                        </IonHeader>
                        <div>
                            <h2>{info}</h2>
                            {button}
                            {raceBoard}
                            {this.state.raceForm}
                            {btnTest}
                            <Logos nbr={this.state.parcours.steps} logo={this.state.parcours.logo} enabled={this.state.logo_enabled}
                                    cancel={this.state.cancel} openCancel={() => this.setState({cancel: true})} resCancel={() => this.setState({cancel: false})} reset={() => {localStorage.setItem("state", ""); window.location.reload();}} />
                            <Time nbr={this.state.parcours.steps} times={this.state.times} />
                            {this.state.notification}
                        </div>
                    </IonContent>
                </IonPage>
    }
}

export default RaceDashboard;