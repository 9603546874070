import React from 'react';
import { useIonToast } from '@ionic/react';
import RaceDashboard from '../components/RaceDashboard';
import './Tab1.css';

const Tab1: React.FC = () => {
  const [present, dismiss] = useIonToast();
  return(
        <RaceDashboard present={present} />
  );
};

export default Tab1;
