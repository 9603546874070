import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { medal, navigate, footsteps, information, camera } from 'ionicons/icons';
import Tab1 from './pages/Tab1';
import Tab2 from './pages/Tab2';
import TabResult from './pages/TabResult';
import TabResultat from './pages/TabResultat';
import TabInfo from './pages/Tab4';
import TabScann from './pages/TabScann';
import TabSteps from './pages/TabSteps';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <IonTabs>
        <IonRouterOutlet>
          <Route path="/infos" component={Tab1} exact={true} />
          <Route exact path="/steps">
            <TabSteps />
          </Route>
          <Route exact path="/tab2">
            <Tab2 />
          </Route>
          <Route path="/res">
            <TabResult />
          </Route>
          <Route path="/resultat/:id" component={TabResult} />
          <Route path="/info">
            <TabInfo />
          </Route>
          <Route path="/scann">
            <TabScann />
          </Route>
          <Route exact path="/id">
            <Redirect to="/scann" />
          </Route>
          <Route exact path="/id/*">
            <Redirect to="/scann" />
          </Route>
          <Route exact path="/">
            <Redirect to="/scann" />
          </Route>
        </IonRouterOutlet>
        <IonTabBar slot="bottom">
          {/*<IonTabButton tab="tabSteps" href="/steps">
            <IonIcon icon={footsteps} />
            <IonLabel>Etapes</IonLabel>
          </IonTabButton>*/}
          <IonTabButton tab="tabScann" href="/scann">
            <IonIcon icon={camera} />
            <IonLabel>Scanner</IonLabel>
          </IonTabButton>
          {/*<IonTabButton tab="tab2" href="/tab2">
            <IonIcon icon={navigate} />
            <IonLabel>Carte</IonLabel>
          </IonTabButton>*/}
          <IonTabButton tab="res" href="/res">
            <IonIcon icon={medal} />
            <IonLabel>Classement</IonLabel>
          </IonTabButton>
          <IonTabButton tab="info" href="/info">
            <IonIcon icon={information} />
            <IonLabel>Informations</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </IonReactRouter>
  </IonApp>
);

export default App;
