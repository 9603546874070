import { useIonToast } from '@ionic/react';
import RaceDashboard from '../components/RaceDashboard';

const Tab1: React.FC = () => {
    const [present, dismiss] = useIonToast();
  return (
    <RaceDashboard present={present} />
  );
};

export default Tab1;
