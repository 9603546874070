import React from 'react';
import { IonItem, IonLabel, } from '@ionic/react';

class Position extends React.Component<{pos: any, name: any, desc: any}> {
    constructor(props) {
      super(props);
    }

    render() {
        return <IonItem>
        <IonLabel>
          <h2>{this.props.pos}. {this.props.name}</h2>
          <p>{this.props.desc}</p>
        </IonLabel>
      </IonItem>;
    }
  }

  export default Position;