import React, { Component } from 'react';
import QrReader from 'react-qr-reader';
import { IonButton, IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react';
import { camera, closeCircle } from 'ionicons/icons';

interface IPart {
    scannerResult: any;
}

interface IState {
    QRScannerVisible: Boolean;
}

class Scanner extends React.Component<{items: IPart, present: any}> {
    scannerStatus: Boolean;
    scanner: any;
    lastResult: any;
    uID: any;
    state: IState;

    constructor(props) {
      super(props);
      this.scanned_error = this.scanned_error.bind(this);
      this.scanned_result = this.scanned_result.bind(this);
      this.state = {QRScannerVisible: false};
    }
    
    componentDidMount() {
        this.scannerStatus = false;
        this.scanner = <></>;
        this.lastResult = "";
        // Detect if a race is loaded
        const race_id = localStorage.getItem('race_id') ? localStorage.getItem('race_id') : '';
        this.setState({QRScannerVisible: false, race_id: race_id});
    }

    componentWillUnmount() {
        this.setState({QRScannerVisible: false});
        this.forceUpdate();
    }

    scanned_error(data: any) {
        if(data) {
            console.log(data);
            this.lastResult = data;
            this.scanner = <></>;
            this.scannerStatus = false;
            this.forceUpdate();
        }
    }

    scanned_result(data: any) {
        if(data) {
            if(!data.startsWith("https://parcours.valais.cloud")) {
                console.log("Unvalid QR Code");
                this.props.present("QR code non valide", 2000);
                return 0;
            } else {
                this.props.items.scannerResult(data);
                this.lastResult = data;
                this.scanner = <></>;
                this.setState({ QRScannerVisible: false});
                this.scannerStatus = false;
                this.forceUpdate();
                return 1;
            }
        } 
    }
    
    render() {
        let scanner = <></>;
        let cancel  = <></>;
        //const QRScannerVisible = this.state.userInput;
        if(this.state.QRScannerVisible) {
            cancel = <IonButton color="danger" onClick={() => this.setState({QRScannerVisible: false})}>
                        <IonIcon slot="icon-only" icon={closeCircle} />
                    </IonButton>
            scanner = <QrReader delay={300} onError={this.scanned_error} onScan={this.scanned_result} style={{ height: '100%' }} />;
        } else {
            scanner = <div>
                        <IonButton expand="full" onClick={() => this.setState({QRScannerVisible: true})}>
                            <IonIcon slot="icon-only" icon={camera} />
                        </IonButton>
                        </div>;
        }
      return <IonGrid>
                <IonRow>
                    <IonCol>{cancel}</IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>{scanner}</IonCol>
                </IonRow>
            </IonGrid>
    }
  }

  export default Scanner;