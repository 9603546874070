import React, { Component } from 'react';
import { IonContent } from '@ionic/react';

class Info extends React.Component {
    constructor(props) {
      super(props);
    }
  
    render() {
      return    <div className="container_bo">
                  <p>Les données personnelles que vous transmettez sont nécessaire à l'enregistrement de votre 
                      parcours ou au calcul de votre catégorie.
                      <br />
                      Par la saisie de vos données, <strong>vous acceptez que votre nom, prénom, localité et catégorie soit affiché 
                      sur la page des résultats, disponible en ligne</strong>.
                  </p>
                  <p>
                      Les informations n'étant pas nécessaire aux points ci-dessus ne sont pas obligatoire pour la participation.
                  </p>
                  <p>
                        Toutes les données saisies lors de votre participation seront transmisent à l'organisateur de la course 
                      et conservées sur nos serveurs. <strong>Les données collectées ne sont pas transmisent à des tiers, à l'exception 
                      de l'organisateur de la course</strong>.
                  </p>
                  <p>
                      Par la saisie de votre adresse de messagerie électronique, <strong>vous acceptez d'être contacté au maximum 
                      une fois par mois au sujet d'autres parcours disponibles</strong>. Vous avez la possibité à tout moment 
                      de retirer votre consentement en faisant une demande à <a href='mailto:data@valais.cloud'>data@valais.cloud</a>.
                  </p>
                </div>
    }
  }

  export default Info;