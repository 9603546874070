import React from 'react';
import { IonButton, IonLabel } from '@ionic/react';

class Finish extends React.Component<{res: any, restart: any}> {
    constructor(props) {
      super(props);
    }

    render() {
        
        return <>
                <p>Félicitations, vous avez terminé la course en <strong><IonLabel color="success">{this.props.res}</IonLabel></strong></p><br />
                <IonButton onClick={this.props.restart} color="danger">Nouvelle course</IonButton>
              </>;
    }
  }

  export default Finish;